import React from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import MyVerticallyCenteredModal from "./modal";
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;

const slidermainparticle = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div class="alert my_bg-class" role="alert">
        <div
          className="alert-text"
          onClick={() => {
            window.open(
              "https://solscan.io/token/RZRyr758BfvKcQTVp1XQoCe9BH2t724XQkLJkiikQ5f",
              "_blank"
            );
          }}
        >
          RZR Token Contract Address:
          RZRyr758BfvKcQTVp1XQoCe9BH2t724XQkLJkiikQ5f
        </div>
      </div>

      <div
        class="alert my_bg-class2"
        onClick={() => {
          window.open(
            "https://explorer.solana.com/tx/4GxBWx5czAvc1sdC7zeVJ63T37i1esXapnabnuBoQsQWL8Yn47YSSac4XumcW6tr7QqLoecZKumdsfJNQcck7N2Z",
            "_blank"
          );
        }}
      >
        <img
          src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1706266357/token_burn_1_up1nc0.svg"
          alt="..."
        />
      </div>

      {/* <div
        class="alert my_bg-class1"
        role="alert"
        onClick={() => {
          window.open("https://t.me/RaiseR_Platform", "_blank");
        }}
      >
        <div className="text-raiseR-one" style={{ marginBottom: "-2.5rem" }}>
          Bridge Your RZR Tokens To Solana
        </div>
        <div className="sec-raiseR-three">
          <img
            className="logo-rzr-1"
            src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1702890085/erc20_lmtcs2.svg"
            alt="..."
          />
          <img
            src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1702890085/Group_1434_qlkubk.svg"
            alt="..."
          />
          <img
            className="logo-rzr-2"
            src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1702890085/spl_n9z08c.svg"
            alt="..."
          />
        </div>
        <div className="text-raiseR-two" style={{ marginTop: "-2.5rem" }}>
          Follow the steps in the pinned message on the Telegram group
        </div>
      </div> */}

      <div className="container">
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />

        <div className="row align-items-center">
          <div className="col-lg-8 col-md-6 col-sm-12">
            <div className="spacer-single"></div>

            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={300}
              duration={900}
              triggerOnce
            >
              <h1 className="col-white">
                A Permissionless <br />
                Credit Market for Everyone.
              </h1>
            </Reveal>
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={600}
              duration={900}
              triggerOnce
            >
              <p className="lead col-white">
                100% of Revenue Reserved for the Community
              </p>
            </Reveal>
            <div className="spacer-10"></div>

            <Reveal
              className="onStep d-inline"
              keyframes={inline}
              delay={800}
              duration={900}
              triggerOnce
            >
              <div className="btn-section mt-3">
                <span
                  onClick={() =>
                    window.open("https://app.raiserdapp.com/", "_blank")
                  }
                  className="btn-main inline lead z-10"
                >
                  Borrow
                </span>
                <div className="mb-sm-30"></div>

                <span
                  onClick={() =>
                    window.open("https://app.raiserdapp.com/", "_blank")
                  }
                  className="btn-main inline lead"
                >
                  Lend
                </span>
                <div className="mb-sm-30"></div>

                <span
                  onClick={() =>
                    window.open("https://app.raiserdapp.com/", "_blank")
                  }
                  className="btn-main inline lead"
                >
                  Trade
                </span>
                <div className="mb-sm-30"></div>
              </div>
            </Reveal>
            <Reveal
              className="onStep d-inline"
              keyframes={inline}
              delay={900}
              duration={1200}
              triggerOnce
            >
              <div className="row">
                <div className="spacer-single"></div>
                <div className="row mt-3">
                  <div className="col-lg-3 col-md-6 col-sm-4 mb30">
                    <div className="de_count">
                      <img
                        className="hero_logo"
                        src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687507246/Vector_9_jluafl.svg"
                      />
                      <h6 className="id-color">
                        Fully decentralized and not privileged
                      </h6>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-4 mb30">
                    <div className="de_count">
                      <img
                        className="hero_logo"
                        src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687507245/Vector_10_wferep.svg"
                      />
                      <h6 className="id-color">
                        Raise funding with or without collateral
                      </h6>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-4 mb30">
                    <div className="de_count ">
                      <img
                        className="hero_logo"
                        src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687507271/carbon_center-to-fit_1_gpid8o.svg"
                      />
                      <h6 className="id-color">
                        Independent 3rd party credit evaluations
                      </h6>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-4 mb30">
                    <div className="de_count">
                      <img
                        className="hero_logo"
                        src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687507318/Union_gslvl0.svg"
                      />
                      <h6 className="id-color">
                        Immediate secondary markets for all successful raises
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Reveal>
          </div>
          <div
            className="col-lg-4 col-md-6  col-sm-12"
            onClick={() => {
              window.open("https://app.raiserdapp.com/", "_blank");
            }}
          >
            <div className="app-button-container">
              <div className="app-button-header">
                Platform is now Live on Solana Mainnet!
              </div>

              <button className="app-button">
                <div className="app-button-text">Launch dAPP</div>
              </button>
            </div>
            {/* <Reveal
              className="onStep d-inline"
              keyframes={inline}
              delay={300}
              duration={1200}
              triggerOnce
            >
              <img
              src="./img/misc/women-with-vr.png"
              className="img-fluid"
              alt=""
            />
            </Reveal> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default slidermainparticle;
