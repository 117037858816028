import React from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import MyVerticallyCenteredModal from "./modal";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;
const featurebox = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className="row">
       <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="col-lg-8">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-3">
            <div className="section-card f-boxed style-3">
              <div className="text">
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={100}
                  duration={600}
                  triggerOnce
                >
                  <h4 className="">Borrow For Your Specific Needs</h4>
                </Reveal>
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={200}
                  duration={600}
                  triggerOnce
                ></Reveal>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <div className="section-card f-boxed style-3">
              <div className="text">
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={100}
                  duration={600}
                  triggerOnce
                >
                  <h4 className="">Set Your Duration</h4>
                </Reveal>
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={200}
                  duration={600}
                  triggerOnce
                ></Reveal>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-3">
            <div className="section-card f-boxed style-3">
              <div className="text">
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={100}
                  duration={600}
                  triggerOnce
                >
                  <h4 className="">Set Your Rate</h4>
                </Reveal>
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={200}
                  duration={600}
                  triggerOnce
                ></Reveal>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 mb-3">
            <div className="section-card f-boxed style-3">
              <div className="text">
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={100}
                  duration={600}
                  triggerOnce
                >
                  <h4 className="">Pledge Collateral (Optional)</h4>
                </Reveal>
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={200}
                  duration={600}
                  triggerOnce
                ></Reveal>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <div className="section-card f-boxed style-3">
              <div className="text">
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={100}
                  duration={600}
                  triggerOnce
                >
                  <h4 className="">No Liquidation Risk</h4>
                </Reveal>
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={200}
                  duration={600}
                  triggerOnce
                ></Reveal>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3"></div>
          <div className="col-lg-4  d-flex justify-content-center">
            <Reveal
              className="onStep d-inline"
              keyframes={inline}
              delay={800}
              duration={900}
              triggerOnce
            >
              <span
                      onClick={() =>window.open(
                        "https://app.raiserdapp.com/",
                        "_blank"
                      )}
                className="btn-main inline lead"
              >
                Become a borrower
              </span>
              <div className="mb-sm-30"></div>
            </Reveal>
          </div>
        </div>
      </div>
      <div className="col-lg-4 second-right-section">
        <img
        className="second-section-image"
          src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687435317/Group_1410_sjhugn.svg"
          alt="..."
        />
      </div>
    </div>
  );
};
export default featurebox;
