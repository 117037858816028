import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter "
      centered
    >
      <Modal.Body>
        <div className="d-flex flex-column text-center align-items-center">
          <h1>Stay Tuned</h1>
          <h3>We are launching soon</h3>
          <span onClick={props.onHide} className="btn-main inline lead">
            Got It!
          </span>
        </div>
      </Modal.Body>

      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}
