import React, { useEffect, useState } from "react";
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints,
} from "react-socks";
import { header } from "react-bootstrap";
import { Link } from "@reach/router";
import useOnclickOutside from "react-cool-onclickoutside";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? "active" : "non-active",
      };
    }}
  />
);

const Header = function () {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const handleBtnClick = (): void => {
    setOpenMenu(!openMenu);
  };
  const handleBtnClick1 = (): void => {
    setOpenMenu1(!openMenu1);
  };
  const handleBtnClick2 = (): void => {
    setOpenMenu2(!openMenu2);
  };
  const handleBtnClick3 = (): void => {
    setOpenMenu3(!openMenu3);
  };
  const closeMenu = (): void => {
    setOpenMenu(false);
  };
  const closeMenu1 = (): void => {
    setOpenMenu1(false);
  };
  const closeMenu2 = (): void => {
    setOpenMenu2(false);
  };
  const closeMenu3 = (): void => {
    setOpenMenu3(false);
  };
  const ref = useOnclickOutside(() => {
    closeMenu();
  });
  const ref1 = useOnclickOutside(() => {
    closeMenu1();
  });
  const ref2 = useOnclickOutside(() => {
    closeMenu2();
  });
  const ref3 = useOnclickOutside(() => {
    closeMenu3();
  });

  const [showmenu, btn_icon] = useState(false);
  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
      if (window.pageYOffset > sticky) {
        closeMenu();
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);
  return (
    <header id="myHeader" className="navbar white">
      <div className="container">
    
        <div className="row w-100-nav">
          <div className="logo px-0">
            <div className="navbar-title navbar-item">
              <NavLink to="/">
                <img
                  src="./img/logo-3.png"
                  className="img-fluid d-block"
                  alt="#"
                />
                <img
                  src="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687461416/Group_1_xotau0.svg"
                  className="img-fluid d-3"
                  alt="#"
                />
              </NavLink>
            </div>
          </div>
          

          {/* <div className='search'>
            <input id="quick_search" className="xs-hide" name="quick_search" placeholder="search item here..." type="text" />
          </div> */}

          <BreakpointProvider>
            <Breakpoint l down>
              {showmenu && (
                <div className="menu">
                  <div className="navbar-item">
                    <div ref={ref}>
                      <div
                        className="dropdown-custom dropdown-toggle btn"
                        onClick={handleBtnClick}
                      >
                        Community
                      </div>
                      {openMenu && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu}>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://twitter.com/raiserdex"
                            >
                              Twitter
                            </a>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://t.me/RaiseR_Platform"
                            >
                              Telegram
                            </a>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://discord.gg/f6R5DyzsZN"
                            >
                              Discord
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="navbar-item">
                    <div ref={ref}>
                      <div
                        className="dropdown-custom dropdown-toggle btn"
                        onClick={handleBtnClick}
                      >
                        Docs
                      </div>
                      {openMenu && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu}>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://docs.raiserdapp.com/"
                            >
                              Gitbook
                            </a>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687509471/Raiser_Deck_compressed_1_vhmhhl.pdf"
                            >
                              Deck
                            </a>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687509441/Raiser_LitePaper_compressed_wpynmo.pdf"
                            >
                              Litepaper
                            </a>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://github.com/RaiseR-Dex/raiser-contract"
                            >
                              Github
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Breakpoint>

            <Breakpoint xl>
              <div className="menu">
                <div className="navbar-item">
                  <div ref={ref}>
                    <div
                      className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick}
                      onMouseLeave={closeMenu}
                    >
                      Docs
                      <span className="lines"></span>
                      {openMenu && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu}>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://docs.raiserdapp.com/"
                            >
                              Gitbook
                            </a>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687509471/Raiser_Deck_compressed_1_vhmhhl.pdf"
                            >
                              Deck
                            </a>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687509441/Raiser_LitePaper_compressed_wpynmo.pdf"
                            >
                              Litepaper
                            </a>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://github.com/RaiseR-Dex/raiser-contract"
                            >
                              Github
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="navbar-item">
                  <div ref={ref2}>
                    <div
                      className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick2}
                      onMouseLeave={closeMenu2}
                    >
                      Community
                      <span className="lines"></span>
                      {openMenu2 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu2}>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://twitter.com/raiserdex"
                            >
                              Twitter
                            </a>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://t.me/RaiseR_Platform"
                            >
                              Telegram
                            </a>
                            <a
                              aria-current="page"
                              target="_blank"
                              href="https://discord.gg/f6R5DyzsZN"
                            >
                              Discord
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Breakpoint>
          </BreakpointProvider>
          {/* 
              <div className='mainside'>
                <NavLink to="/wallet" className="btn-main">Connect Wallet</NavLink>
              </div> */}
        </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>
    </header>
  );
};
export default Header;
