import React from "react";
import { Link } from "@reach/router";
import { MdEmail } from "react-icons/md";
const footer = () => (
  <footer className="footer-light">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-6 col-xs-1 d-flex justify-content-center">
          <div className="widget">
            <h5>Key Concepts</h5>
            <ul>
              <li>
                <a
                  aria-current="page"
                  target="_blank"
                  href="https://docs.raiserdapp.com/how-does-raiser-work/borrowers"
                >
                  Borrowers
                </a>
              </li>
              <li>
                <a
                  aria-current="page"
                  target="_blank"
                  href="https://docs.raiserdapp.com/governance"
                >
                  Governance
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-1 d-flex justify-content-center">
          <div className="widget">
            <h5>Resources</h5>
            <ul>
              <li>
                <a
                  aria-current="page"
                  target="_blank"
                  href="https://docs.raiserdapp.com/"
                >
                  Gitbook
                </a>
              </li>
              <li>
                <a
                  aria-current="page"
                  target="_blank"
                  href="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687509471/Raiser_Deck_compressed_1_vhmhhl.pdf"
                >
                  Deck
                </a>
              </li>
              <li>
                <a
                  aria-current="page"
                  target="_blank"
                  href="https://res.cloudinary.com/drr1rnoxf/image/upload/v1687509441/Raiser_LitePaper_compressed_wpynmo.pdf"
                >
                  Litepaper
                </a>
              </li>
              <li>
                <a
                  aria-current="page"
                  target="_blank"
                  href="https://github.com/RaiseR-Dex/raiser-contract"
                >
                  Github
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-1 d-flex justify-content-center">
          <div className="widget">
            <h5>Community</h5>
            <ul>
              <li>
                <a
                  aria-current="page"
                  target="_blank"
                  href="https://twitter.com/raiserdex"
                >
                  Twitter
                </a>
              </li>
              <li>
                <a
                  aria-current="page"
                  target="_blank"
                  href="https://t.me/RaiseR_Platform"
                >
                  Telegram
                </a>
              </li>
              <li>
                <a
                  aria-current="page"
                  target="_blank"
                  href="https://discord.gg/f6R5DyzsZN"
                >
                  Discord
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-1 d-flex justify-content-center">
          <div className="widget">
            <h5>Contact us</h5>
            <ul>
              <li>
                <a aria-current="page" href="mailto: support@raiserdapp.com">
                  <MdEmail className="mail-icon" />
                  support@raiserdapp.com
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="col-md-3 col-sm-6 col-xs-1">
                        <div className="widget">
                            <h5>Newsletter</h5>
                            <p>Signup for our newsletter to get the latest news in your inbox.</p>
                            <form action="#" className="row form-dark" id="form_subscribe" method="post" name="form_subscribe">
                                <div className="col text-center">
                                    <input className="form-control" id="txt_subscribe" name="txt_subscribe" placeholder="enter your email" type="text" /> 
                                    <Link to="" id="btn-subscribe">
                                      <i className="arrow_right bg-color-secondary"></i>
                                    </Link>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                            <div className="spacer-10"></div>
                            <small>Your email is safe with us. We don't spam.</small>
                        </div>
                    </div> */}
      </div>
    </div>
  </footer>
);
export default footer;
