import React from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import {FiArrowUpRight} from "react-icons/fi"
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;
const tokenesection = () => (
  <div className="row">
    <div className="col-lg-6 forth-right-section ">
      <h4 style={{ color: "#2297F1" }}>POWERED BY</h4>
      <div className="token-left mt-3">
        RZR Token
        <br />
        Fuel of the Platform
      </div>
    </div>

    <div className="col-lg-6 d-flex justify-content-center">
      <div className="section-card f-boxed token-card style-3 text-center">
        <div className="uniswap-contract">
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={100}
            duration={600}
            triggerOnce
          >
            <h4 className="rzr-token-contract"
             onClick={()=>{
                window.open('https://solscan.io/token/RZRyr758BfvKcQTVp1XQoCe9BH2t724XQkLJkiikQ5f','_blank')
            }}
            >RZR Token Contract
            <FiArrowUpRight className="right-arrow" />
            </h4>
            <div className="uniswap-btn"
            onClick={()=>{
                window.open('https://raydium.io/liquidity/add/?coin0=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&coin1=RZRyr758BfvKcQTVp1XQoCe9BH2t724XQkLJkiikQ5f&fixed=coin0','_blank')
            }}
            >
              <div    className="uniswap-btn-text">BUY RZR</div>

              <img
              className="uniswap-logo"
                src="	https://img.raydium.io/icon/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R.png"
                alt="..."
              />
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  </div>
);
export default tokenesection;
